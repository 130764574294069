@import './../../../../scss/theme-bootstrap';

// Split-width template
.content-block5 {
  width: 100%;
  overflow: hidden;
  &__innerwrap {
    max-width: 1020px;
    margin: 0 auto;
  }
  &__full-heading {
    @include swap_direction(margin, 100px -80px 100px -80px);
    @include h6;
    color: $color-white;
    text-align: center;
    clear: both;
    &__text {
      @include breakpoint($medium-up) {
        font-size: 18px;
      }
      padding: 0 8px;
      // Line though text decoration
      display: table;
      white-space: nowrap;
      font-weight: normal;
      &:before,
      &:after {
        border-top: 1px solid $color-darker-gray;
        content: '';
        display: table-cell;
        position: relative;
        top: 0.9em;
        width: 45%;
      }
      &:before {
        #{$rdirection}: 1.5%;
      }
      &:after {
        #{$ldirection}: 1.5%;
      }
    }
  }
  &__box {
    background-color: $color-black;
    height: auto;
    position: relative;
    margin-bottom: 60px;
    @include breakpoint($medium-up) {
      margin-bottom: 110px;
      width: 46.5%;
    }
    &-bambuser {
      cursor: pointer;
      .content-block5__cta-button {
        pointer-events: none;
      }
    }
    &--left {
      @include breakpoint($medium-up) {
        float: #{$ldirection};
      }
    }
    &--right {
      @include breakpoint($medium-up) {
        float: #{$rdirection};
      }
    }
  }
  &__heading {
    color: $color-white;
    line-height: 1.3em;
    margin-top: 10px;
    font-size: 15px;
    font-weight: 100;
    margin-bottom: 10px;
  }
  &__subheading-text {
    background-color: transparent;
    display: inline-block;
    text-align: center;
    color: $color-white;
    line-height: 1.3em;
    padding: 0 8px;
    @include h2-bare;
  }
  &__maintext {
    text-align: center;
    color: $color-white;
    font-size: 10px;
    letter-spacing: 0.13em;
    font-weight: normal;
    margin-top: 10px;
    margin-bottom: 15px;
    @include breakpoint($medium-up) {
      font-size: 12px;
      margin-top: 10px;
      margin-bottom: 15px;
    }
    @include breakpoint($landscape-up) {
      margin-top: 20px;
      margin-bottom: 25px;
    }
  }
  &__button {
    margin-bottom: 25px;
    @include breakpoint($medium-up) {
      @include swap_direction(margin, 10px 0 20px 0);
    }
    @include breakpoint($landscape-up) {
      @include swap_direction(margin, 30px 0 45px 0);
    }
    &__cta-button {
      border: 1px solid $color-white;
    }
    .button {
      display: inline-block;
      background-color: transparent;
    }
    .button-link {
      text-decoration: underline;
      color: $color-white;
      border-top: none;
    }
    &--opt1 {
      border: 1px solid $color-white;
    }
    &--opt2 {
      border: 1px solid black;
    }
    &--opt4 {
      @include button-secondary-inverted;
    }
  }
  &__image {
    width: 100%;
    text-align: center;
    background: $color-black;
    & img {
      width: 100%;
      max-width: 2200px;
    }
  }

  // Inner text/button container ============
  &__inner {
    position: relative;
    margin-top: -30%;
    padding: 1px;
    width: 100%;
    text-align: center;
    @include breakpoint($medium-up) {
      position: absolute;
      z-index: 3;
    }
    &-textbox {
      margin-#{$ldirection}: auto;
      margin-#{$rdirection}: auto;
      width: 100%;
    }
  }
  &__bottom-promo {
    margin: 0 auto;
    padding: 30px 0;
    overflow: hidden;
    position: relative;
    clear: both;
    max-width: 1020px;
  }
}
// Desktop
@include breakpoint($medium-up) {
  .content-block5__textbox-pos--centered {
    .content-block5__inner {
      @include vertical-horizontal-center;
      position: absolute;
    }
  }
  .content-block5__textbox-pos--bottom {
    .content-block5__inner {
      bottom: 0;
      position: absolute;
    }
  }
  .content-block5__textbox-pos--left {
    .content-block5__inner {
      width: 50%;
      height: 100%;
      -webkit-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
      transform: translate(0, -50%);
      top: 50%;
      position: absolute;
      #{$ldirection}: 0;
    }
  }
  .content-block5__textbox-pos--right {
    .content-block5__inner {
      width: 50%;
      height: 100%;
      -webkit-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
      transform: translate(0, -50%);
      top: 50%;
      position: absolute;
      #{$rdirection}: 0;
    }
  }
  .content-block5__textbox-pos--outertop {
    display: table;
    .content-block5__image {
      display: table-row-group;
    }
    .content-block5__inner {
      position: static;
      display: table-header-group;
    }
  }
  .content-block5__textbox-pos--outerlow {
    .content-block5__inner {
      position: static;
    }
  }
}
